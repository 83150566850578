@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-primary: #0f2a42;
    --color-primary-transparent: #0f2a4244;
    --color-secondary: #245b92;
    --color-secondary-transparent: #245b9288;
    --color-accent: #5d98a1;
    --color-light: #eceded;
    --color-light-shadow: #eceded88;
    --padding-double: 40px;
    --padding-default: 20px;
    --padding-half: 10px;
    --border-radius: 8px;
}

html, body {
    height: 100%;
    min-height: 100%;

    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
}

.primary-button {
    border: none;
    width: 100%;
    padding: var(--padding-half);
    font-weight: bold;
    font-size: 16px;
    color: var(--color-light);
    background-color: var(--color-secondary);
}

.primary-button.disabled {
    background-color: var(--color-secondary-transparent);
}

#progressContainer {
    display: none;
}

.progressBar {
    height: 4px;
    background-color: rgba(5, 114, 206, 0.2);
    width: 100%;
    overflow: hidden;
}

.progressBarValue {
    width: 100%;
    height: 100%;
    background-color: rgb(5, 114, 206);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform:  translateX(0) scaleX(0);
    }
    40% {
        transform:  translateX(0) scaleX(0.4);
    }
    100% {
        transform:  translateX(100%) scaleX(0.5);
    }
}

#strich-partner-link {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 4px;
}
